<template>
  <VMenu
    attach
    :close-on-content-click="false"
    content-class="br-4"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex mt-2">
        <button
          v-bind="attrs"
          class="pl-2 py-2 d-flex align-center br-4 grey lighten-5"
          type="button"
          v-on="on"
        >
          <VIcon
            class="mr-2 mb-auto"
            color="#A4A2B7"
            v-text="'mdi-clock-time-four-outline'"
          />
          <div class="d-flex flex-wrap">
            <div class="subtitle-1 mr-2">
              {{ durationTitle }}
            </div>
            <div class="subtitle-1">
              {{ selectedDaysTitle }}
            </div>
          </div>
        </button>
        <VBtn
          class="my-auto ml-1"
          icon
          small
          @click="$emit('delete')"
        >
          <VIcon
            color="#4D4F67"
            v-text="'mdi-close'"
          />
        </VBtn>
      </div>
    </template>
    <VCard class="pt-4 pb-3 px-3">
      <div class="d-flex">
        <div class="d-flex align-center greyish time-field pl-1 mx-1">
          <input
            v-model="startTimeToEdit[0]"
            v-mask="'##'"
            class="time-box subtitle-2 text-center"
          >
          :
          <input
            v-model="startTimeToEdit[1]"
            v-mask="'##'"
            class="time-box subtitle-2 text-center"
          />
        </div>
        <div class="d-flex align-center greyish time-field pl-1 mx-1">
          <input
            v-model="endTimeToEdit[0]"
            v-mask="'##'"
            class="time-box subtitle-2 text-center"
          >
          :
          <input
            v-model="endTimeToEdit[1]"
            v-mask="'##'"
            class="time-box subtitle-2 text-center"
          />
        </div>
      </div>
      <div>
        <div class="subtitle-1 font-weight-bold mt-3 mb-1 pl-1">
          Повтор
        </div>
        <div
          v-for="(day, index) in weekdaysLong"
          :key="index"
          class="d-flex align-center pl-2"
        >
          <VCheckbox
            v-model="selectedDays"
            class="mt-auto mb-1 light-grey-checkbox"
            color="#E43539"
            hide-details
            :ripple="false"
            :value="index + 1"
          />
          <span class="subtitle-2">
            {{ day }}
          </span>
        </div>
      </div>
    </VCard>
  </VMenu>
</template>

<script>

export default {
  props: {
    startTime: {
      type: String,
      default: '00:00:00',
    },
    endTime: {
      type: String,
      default: '23:59:59',
    },
    weekdays: {
      type: Array,
      default: () => [], // [1, 2, 3, 4, 5, 6, 7]
    },
  },
  emits: ['delete', 'startTime', 'endTime', 'weekdays'],
  data() {
    return {
      startTimeToEdit: ['00', '00'],
      endTimeToEdit: ['23', '59'],
      weekdaysLong: ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'],
      weekdaysShort: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
      selectedDays: [],
    };
  },
  mounted() {
    if (this.startTime) {
      this.startTimeToEdit = this.startTime.split(':');
    }
    if (this.endTime) {
      this.endTimeToEdit = this.endTime.split(':');
    }
    if (this.weekdays.length) {
      this.selectedDays = this.weekdays;
    }
  },
  computed: {
    selectedDaysTitle() {
      if (this.selectedDays.length === 7) {
        return 'Все дни';
      }
      if (this.selectedDays.length === 5 && !this.selectedDays.some((day) => day > 5)) {
        return 'Будни';
      }
      if (this.selectedDays.length === 2 && !this.selectedDays.some((day) => day < 6)) {
        return 'Выходные';
      }
      return this.selectedDays.map(day => this.weekdaysShort[day - 1]).join(', ');
    },
    durationTitle() {
      const start = `${this.startTimeToEdit[0]}:${this.startTimeToEdit[1]}`;
      const end = `${this.endTimeToEdit[0]}:${this.endTimeToEdit[1]}`;
      return `${[start, end].join(' → ')}`;
    },
  },
  watch: {
    startTime(val) {
      if (val) {
        this.startTimeToEdit = val.split(':');
      }
    },
    endTime(val) {
      if (val) {
        this.endTimeToEdit = val.split(':');
      }
    },
    weekdays(val) {
      if (val.length) {
        this.selectedDays = val;
      }
    },
    startTimeToEdit(val) {
      this.$emit('startTime', `${val[0]}:${val[1]}:00`);
    },
    endTimeToEdit(val) {
      this.$emit('endTime', `${val[0]}:${val[1]}:59`);
    },
    selectedDays(val) {
      this.$emit('weekdays', val);
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  width: 248px;
}

.time-field {
  width: 50% !important;
  height: 34px;
  border-radius: 4px;
}

.time-box {
  width: 25px;
}

.subtitle-1 {
  line-height: 20px;
}

::v-deep {
  .light-grey-checkbox .mdi-checkbox-blank-outline {
    color: #E6E6E9;
  }
}
</style>
