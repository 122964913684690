<template>
  <div>
    <div class="pt-5 px-8">
      <PageHeader
        create-btn-title="Создать коллекцию"
        hide-menu
        page-title="Коллекции"
        @click:create="edit(null)"
        @search-input="searchInput = $event"
      />
      <VDialog
        v-model="collectionDialog"
        content-class="br-12 collection-dialog"
        max-width="1300"
      >
        <Collection
          :id="collectionToEditId"
          :key="key"
          :cities="total.cities"
          :collections="collections"
          :targets="total.targets"
          @close="collectionDialog = false"
          @update="getCollections(currentPage)"
        />
      </VDialog>
      <VDivider />
      <div class="d-flex my-4">
        <Dropdown
          :items="total.targets"
          :selected="selected.targets"
          title="Все платформы"
          @selected="updateSelected('targets', $event)"
        />
        <Dropdown
          height="420"
          :items="total.cities"
          searchable
          :selected="selected.cities"
          title="Все регионы"
          @selected="updateSelected('cities', $event)"
        />
        <Dropdown
          :items="total.states"
          :selected="selected.states"
          title="Все статусы"
          @selected="updateSelected('states', $event)"
        />
      </div>
    </div>
    <UniversalTable
      ref="table"
      :headers="collectionHeaders()"
      :items="collections"
      :page="currentPage"
      @delete="deleteCollection"
      @edit="edit($event.id)"
      @update-page="getCollections"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getTargetList, fetchCollections, deleteCollection } from '@/api/api';
import { collectionHeaders } from '@/conditions/tablesHeaders';
import PageHeader from '@/components/reusable/PageHeader.vue';
import Collection from '@/components/Collection.vue';
import UniversalTable from '@/components/reusable/UniversalTable.vue';
import Dropdown from '@/components/reusable/DropdownTable.vue';

const collectionStates = [{ title: 'Активно', value: 'active' }, { title: 'Отключено', value: 'disabled' }];

export default {
  components: {
    Dropdown,
    UniversalTable,
    Collection,
    PageHeader,
  },
  data() {
    return {
      collections: [],
      collectionDialog: false,
      collectionToEditId: null,
      collectionHeaders,
      key: 0,
      currentPage: 1,
      total: {
        targets: [],
        cities: [],
        states: collectionStates,
      },
      selected: {
        targets: [],
        cities: [],
        states: collectionStates,
      },
      searchInput: '',
    };
  },
  mounted() {
    this.getCitiesShort().then(() => {
      this.total.cities = this.citiesShort;
      this.selected.cities = this.citiesShort;
    });
    getTargetList().then((response) => {
      this.total.targets = response;
      this.selected.targets = response;
    });
    this.getCollections();
  },
  computed: {
    ...mapGetters(['citiesShort']),
  },
  methods: {
    ...mapActions(['getCitiesShort']),
    edit(id) {
      this.collectionToEditId = id;
      this.key += 1;
      this.collectionDialog = true;
    },
    updateSelected(key, selected) {
      if ((this.selected[key].length + selected.length) !== this.total[key].length) {
        this.selected[key] = selected;
        this.getCollections();
      } else {
        this.selected[key] = selected;
      }
    },
    getCollections(page = 1) {
      this.$refs.table.loading = true;
      const targets = this.selected.targets;
      const cities = this.selected.cities;
      const states = this.selected.states;
      const params = {
        page,
        search: this.searchInput,
        platforms: targets.length === this.total.targets.length ? '' : targets.map(target => target.code).join(','),
        city_ids: cities.length === this.total.cities.length ? '' : `${cities.map(city => city.id).join(', ')}`,
        status: states.length === 1 ? states[0].value : '',
      };
      fetchCollections(params).then((response) => {
        this.collections = response.data;
        this.currentPage = response.current_page;
        this.$refs.table.totalPage = response.total_page;
        this.$refs.table.totalCount = response.total_count;
      }).finally(() => {
        this.$refs.table.loading = false;
      });
    },
    deleteCollection(collection) {
      deleteCollection(collection.id);
      this.getCollections(this.currentPage);
    },
  },
  watch: {
    searchInput() {
      this.getCollections();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

</style>
