<template>
  <div>
    <div class="d-flex align-center justify-space-between my-2">
      <div id="preset-rests-picker">
        <VInput
          v-model="selectedRestaurants"
          class="grey-subtitle"
          hide-details="auto"
          :rules="rules"
        >
          Добавить рестораны
        </VInput>
      </div>
      <DropdownList
        attach="#preset-rests-picker"
        :current-page="filters.page"
        is-icon
        :items="restaurants"
        :loading="loading"
        no-header
        offset-y
        searchable
        :selected="selectedRestaurants"
        :total-page="totalPages"
        width="400"
        @activated="getRestaurants"
        @page="updateCurrentPage($event.page)"
        @search="updateFilters('title', $event)"
        @selected="$emit('input', $event)"
      >
        <template v-slot:filters>
          <div class="d-flex pt-3 px-2">
            <div>
              <DropdownTable
                :items="deliveryServices"
                no-header
                :selected="filters.deliveries"
                single-select
                title="Все доставки"
                @selected="updateFilters('deliveries', $event)"
              />
            </div>
            <div>
              <DropdownTable
                height="265"
                :items="cities"
                :max="1"
                no-header
                :selected="filters.cities"
                single-select
                title="Все регионы"
                @selected="updateFilters('cities', $event)"
              />
            </div>
          </div>
        </template>
      </DropdownList>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DropdownList from '@/components/reusable/DropdownList.vue';
import DropdownTable from '@/components/reusable/DropdownTable.vue';
import { fetchHubRestaurants } from '@/api/api';

export default {
  components: {
    DropdownList,
    DropdownTable,
  },
  props: {
    value: {
      type: Array, // selected restaurants
      default: () => [],
    },
  },
  emits: ['input'],
  data() {
    return {
      selectedRestaurants: [],
      restaurants: [],
      deliveryServices: [
        { title: 'OD', value: 'OD' },
        { title: 'VD', value: 'VD' },
      ],
      filters: {
        deliveries: [],
        cities: [],
        title: '',
        page: 1,
      },
      cities: [],
      totalPages: 1,
      loading: false,
      rules: [(value) => !!value.length || 'Обязательное поле'],
    };
  },
  created() {
    if (this.value) {
      this.selectedRestaurants = this.value;
    }
    this.cities = this.citiesShort;
  },
  computed: {
    ...mapGetters(['citiesShort']),
  },
  methods: {
    getRestaurants() {
      this.loading = true;
      const params = {
        page: this.filters.page,
        title: this.filters.title,
        will_be_delivered_by: this.filters.deliveries[0]?.value,
        city_id: this.filters.cities[0]?.id,
      };
      fetchHubRestaurants(params).then((response) => {
        this.restaurants = response.dataShort || [];
        this.totalPages = response.totalPages || 1;
      }).finally(() => {
        this.loading = false;
      });
    },
    updateFilters(key, value) {
      this.filters[key] = value;
      this.filters.page = 1;
      this.getRestaurants();
    },
    updateCurrentPage(page) {
      this.filters.page = page;
      this.getRestaurants();
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.selectedRestaurants = val;
      }
    },
  },
};
</script>
