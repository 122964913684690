<template>
  <div>
    <div class="d-flex justify-space-between pt-4 pr-2">
      <VInput
        v-model="selectedCollections"
        class="grey-subtitle"
        hide-details="auto"
        :rules="rules"
      >
        Укажите коллекции
      </VInput>
      <VDialog
        v-model="dialog"
        content-class="br-12"
        max-width="1400"
      >
        <template v-slot:activator="{ on, attrs }">
          <VBtn
            v-bind="attrs"
            icon
            v-on="on"
          >
            <VIcon color="#4D4F67" v-text="'mdi-plus'" />
          </VBtn>
        </template>
        <CollectionSelection
          v-model="selectedCollections"
          :is-shown="dialog"
          @close="dialog = false"
          @input="$emit('input', $event)"
        />
      </VDialog>
    </div>
  </div>
</template>

<script>
import CollectionSelection from '@/components/collection/CollectionSelection.vue';

export default {
  components: {
    CollectionSelection,
  },
  props: {
    value: {
      type: Array, // selected collections
      default: () => [],
    },
  },
  emits: ['input'],
  data() {
    return {
      selectedCollections: [],
      dialog: false,
      rules: [(value) => !!value.length || 'Обязательное поле'],
    };
  },
  created() {
    this.selectedCollections = this.value;
  },
  watch: {
    value(val) {
      this.selectedCollections = val;
    },
  },
};
</script>
