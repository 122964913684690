<template>
  <div>
    <div class="d-flex justify-space-between mt-4 pr-2">
      <div id="foodsPicker">
        <VInput
          v-model="selectedFoods"
          class="grey-subtitle"
          hide-details="auto"
          :rules="rules"
        >
          Укажите блюда
        </VInput>
      </div>
      <DropdownTable
        attach="#foodsPicker"
        :fixed-header="false"
        :height="260"
        is-icon
        item-key="id"
        :items="foods"
        no-data-text="Блюда не найдены"
        :no-header="!foods.length"
        search-placeholder="Поиск блюда"
        :searchable="!!foods.length"
        :selected="selectedFoods"
        title="Все блюда из списка"
        width="300"
        @selected="selectedFoods = $event"
      >
        <template v-slot:filters>
          <RestaurantSelection
            class="pt-2 px-2 pb-3 grey lighten-5"
            clear-btn
            input-placeholder="Введите название ресторана"
            outline-color="#8c8c8c"
            @clear="clearParams"
            @restaurantSelected="handleRestaurantPk($event.pk)"
          />
          <VDivider />
          <div v-if="restaurantPk" class="pl-2 pt-3">
            <DropdownTable
              class="ml-2"
              :height="categories.length > 6 ? '230' : ''"
              :items="categories"
              :max="1"
              no-header
              :selected="selectedCategories"
              single-select
              title="Все категории"
              @selected="handleSelectedCategories"
            />
          </div>
        </template>
      </DropdownTable>
    </div>
  </div>
</template>

<script>
import DropdownTable from '@/components/reusable/DropdownTable.vue';
import RestaurantSelection from '@/components/RestaurantSelection.vue';
import { fetchFoods } from '@/api/api';

export default {
  components: {
    RestaurantSelection,
    DropdownTable,
  },
  props: { // selected foods
    value: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['input'],
  data() {
    return {
      selectedFoods: [],
      foods: [],
      restaurantPk: '',
      categories: [],
      selectedCategories: [],
      rules: [(value) => !!value.length || 'Обязательное поле'],
    };
  },
  created() {
    if (this.value) {
      this.selectedFoods = this.value;
    }
  },
  computed: {
    categoriesQuery() {
      return this.selectedCategories.length ? `?categories=${this.selectedCategories[0].id}` : '';
    },
  },
  methods: {
    getFoods(assignCategories = false) {
      fetchFoods(this.restaurantPk, this.categoriesQuery)
        .then((foods) => {
          this.foods = foods;
          if (assignCategories) {
            const foodCategories = {};
            foods.forEach((food) => {
              food.categories.forEach((category) => {
                foodCategories[category.id] = category.title;
              });
            });
            Object.keys(foodCategories).forEach((key) => {
              this.categories.push({ title: foodCategories[key], id: key });
            });
          }
        });
    },
    handleRestaurantPk(restaurantPk) {
      this.categories = [];
      this.selectedCategories = [];
      this.restaurantPk = restaurantPk;
      this.getFoods(true);
    },
    handleSelectedCategories(categories) {
      this.selectedCategories = categories;
      this.getFoods();
    },
    clearParams() {
      this.restaurantPk = '';
      this.foods = [];
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.selectedFoods = val;
      }
    },
    selectedFoods(val) {
      this.$emit('input', val);
    },
  },
};
</script>
