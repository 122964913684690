<template>
  <div>
    <div class="font-weight-bold mb-2">
      Отображение коллекции
    </div>
    <VMenu
      attach
      content-class="br-8"
      min-width="200"
      offset-y
    >
      <template #activator="{ on, attrs }">
        <VBtn
          v-bind="attrs"
          class="subtitle-1 elevation-0 grey lighten-5 d-flex justify-space-between"
          color="black"
          outlined
          :ripple="false"
          rounded
          style="border: 1px solid #E6E6E9 !important"
          v-on="on"
        >
          <span>{{ designTypes[selected] || 'Выбрать отображение' }}</span>
          <VIcon
            class="ml-2"
            size="18"
            v-text="'mdi-chevron-down'"
          />
        </VBtn>
      </template>
      <VList class="pa-2" nav>
        <VListItemGroup
          v-model="selected"
          color="red"
          @change="$emit('input', $event)"
        >
          <VListItem
            v-for="(key, index) in Object.keys(designTypes)"
            :key="index"
            class="pink--hover"
            :class="{'text--red': key === selected }"
            dense
            :ripple="false"
            :value="key"
          >
            {{ designTypes[key] }}
          </VListItem>
        </VListItemGroup>
      </VList>
    </VMenu>
    <div
      v-if="selected === '1'|| selected === '2'"
      class="d-flex my-3 overflow-auto pr-3"
      :class="{
        'overflow-auto': selected === '1',
        'flex-wrap': selected === '2',
      }"
    >
      <div
        v-for="(box, index) in 4"
        :key="index"
        class="design-box mr-2 mb-2"
      />
    </div>
    <div
      v-if="selected === '3'"
      class="d-flex flex-wrap mt-4"
    >
      <div
        v-for="(tab, index) in exampleDesignTabs"
        :key="index"
        class="design-tab mr-1 mb-1"
      >
        {{ tab }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  emits: ['input'],
  data() {
    return {
      selected: '',
      designTypes: {
        1: 'Горизонтально',
        2: 'Вертикально',
        3: 'Табы',
      },
      exampleDesignTabs: ['Рестораны', 'Азиатская кухня', 'Корейская кухня', 'Казахская кухня'],
    };
  },
  mounted() {
    this.selected = this.value;
  },
};
</script>

<style lang="scss" scoped>
.design-box {
  min-width: 100px;
  height: 52px;
  padding: 4px 6px 4px 6px;
  border-radius: 8px;
  background-color: #E6E6E9;
}

.design-tab {
  padding: 6px 8px;
  background-color: #FAFAFB;
  border-radius: 4px;
}
</style>
