<template>
  <VCard>
    <div class="top-sticky white z-1000">
      <div class="d-flex justify-space-between align-center pa-3">
        <Breadcrumbs :items="breadcrumbs" />
        <VBtn icon @click="$emit('close')">
          <VIcon color="blue-grey darken-3">
            mdi-close
          </VIcon>
        </VBtn>
      </div>
      <VDivider />
    </div>
    <div v-if="loading" class="h-80vh">
      <VProgressCircular
        color="#E43A35"
        indeterminate
        :size="80"
        :width="6"
      />
    </div>
    <VForm
      v-else
      ref="form"
      v-model="valid"
    >
      <VRow class="mx-0">
        <VCol class="py-4 pl-6 pr-10">
          <div>
            <div class="grey-subtitle mb-2">
              Тип коллекции
            </div>
            <VSelect
              v-model="collection.typeOf"
              attach
              background-color="#FAFAFB"
              color="red"
              dense
              flat
              hide-details="auto"
              item-color="red"
              item-text="title"
              item-value="value"
              :items="types"
              placeholder="Выберите тип коллекции"
              :rules="[rules.required]"
              solo
              @input="getCollectionPresets"
            />
          </div>
          <div class="d-flex justify-space-between mt-8">
            <WideTextField
              v-model="collection.titleRu"
              class="mb-auto"
              label="Название коллекции RU"
              :rules="[rules.required, rules.notMoreThan28]"
              style="width: 49%"
            />
            <WideTextField
              v-model="collection.titleKz"
              class="mb-auto"
              label="Название коллекции KZ"
              style="width: 49%"
            />
          </div>
          <div class="grey-description mt-2">
            Ограничение символов в названии коллекции - до 28 символов с учетом пробелов,
            на русском и на казахском языках
          </div>
          <div class="mt-8 mb-3">
            <div class="section-title">
              Загрузите обложку
            </div>
            <span class="grey-description">
              Обложка будет на главной странице приложения. Вес не более 200 кб.
            </span>
            <div class="d-flex align-center mt-4">
              <ImageUploadBox
                v-model="collection.image"
                class="mr-5"
                :deletable="!!collection.image"
                enlargeable
                height="140"
                text="Загрузить обложку"
                width="204"
                @delete="collection.image = ''"
                @open-image="showImage = true"
              />
              <VDialog
                v-model="showImage"
                :transition="false"
                width="900"
              >
                <div class="relative">
                  <VBtn
                    absolute
                    class="z-10 mt-3"
                    fab
                    height="30"
                    right
                    width="30"
                    @click="showImage = false"
                  >
                    <VIcon size="20" v-text="'mdi-close'" />
                  </VBtn>
                  <VImg
                    :height="600"
                    :src="collection.image"
                    :width="900"
                  />
                </div>
              </VDialog>
              <StoryTextField
                v-model="collection.image"
                label="Укажите ссылку"
              />
            </div>
          </div>
          <div class="relative">
            <VOverlay
              absolute
              color="white"
              :value="!collection.typeOf"
            />
            <div>
              <div class="section-title">
                Контент
              </div>
              <VRow class="d-flex">
                <VCol cols="6">
                  <div class="grey-subtitle mt-6 mb-2">
                    Укажите тип контента
                  </div>
                  <VSelect
                    v-model="collection.content.presetId"
                    attach
                    background-color="#FAFAFB"
                    color="red"
                    dense
                    flat
                    hide-details
                    item-color="red"
                    item-text="title"
                    item-value="id"
                    :items="presets"
                    placeholder="Выберите тип коллекции"
                    solo
                    validate-on-blur
                    @change="resetCollectionContentValues"
                  />
                </VCol>
                <VCol
                  v-if="collection.typeOf === 'restaurant' || collection.typeOf === 'food'"
                  cols="6"
                >
                  <div class="grey-subtitle mt-6 mb-2">
                    Выберите сортировку
                  </div>
                  <VSelect
                    v-model="collection.sort"
                    attach
                    background-color="#FAFAFB"
                    color="red"
                    dense
                    flat
                    hide-details="auto"
                    item-color="red"
                    item-text="title"
                    item-value="value"
                    :items="sorts"
                    placeholder="Выберите сортировку"
                    :rules="[rules.required]"
                    solo
                  />
                </VCol>
              </VRow>
            </div>
            <VDivider class="my-4" />
            <PresetFoodsPicker
              v-if="collection.content.presetId === 1"
              v-model="collection.content.valueWithTitles"
            />
            <PresetRestaurantsPicker
              v-if="collection.content.presetId === 2"
              v-model="collection.content.valueWithTitles"
            />
            <PresetCollectionsPicker
              v-if="collection.content.presetId === 3"
              v-model="collection.content.valueWithTitles"
            />
            <PresetMaxDeliveryCost
              v-if="collection.content.presetId === 4"
              v-model="collection.content.int"
            />
            <div
              v-if="collection.content.presetId !== 4 && collection.content.valueWithTitles.length"
            >
              <ChipGroup
                :items="collection.content.valueWithTitles"
                :max="30"
                @remove="deleteContentValue"
              />
              <VBtn
                v-show="collection.content.valueWithTitles.length > 3"
                class="subtitle-2 red--text elevation-0 px-3"
                color="#FFEEEE"
                height="32"
                @click="collection.content.valueWithTitles = []"
              >
                Удалить все
              </VBtn>
              <VDivider class="mt-3" />
            </div>
            <div class="mt-12 mb-8">
              <div class="section-title mb-2">
                SEO настройка
              </div>
              <VRow>
                <VCol class="py-0">
                  <StoryTextField
                    v-model="collection.h1TitleRu"
                    label="H1 заголовок на русском"
                  />
                  <span class="text-description grey--text">
                    Если поле не заполнено, то подставляется название коллекции
                  </span>
                  <StoryTextField
                    v-model="collection.seoTitleRu"
                    class="mt-3"
                    label="SEO заголовок на русском"
                  />
                  <StoryTextField
                    v-model="collection.seoTextRu"
                    label="SEO текст на русском"
                  />
                  <StoryTextField
                    v-model="collection.seoDescriptionRu"
                    label="SEO description на русском"
                  />
                  <div class="text-description grey--text">
                    Если данное поле не заполнено, по умолчанию поле будет заполнено
                    данными из: <br>
                    1. SEO текста (первые 500 символов) или; <br>
                    2. Названия ru и общего описания для всего сайта.
                  </div>
                  <StoryTextField
                    v-model="collection.slug"
                    class="mt-3"
                    label="Slug"
                  />
                  <div class="text-description grey--text">
                    Если это поле не заполнено, то будет заполнение данными из названия ru в
                    латинской транслитерации. Пример: Заказать пиццу в Астане -
                    zakazat-pitsu-v-astane.
                  </div>
                </VCol>
                <VCol class="py-0">
                  <StoryTextField
                    v-model="collection.h1TitleKz"
                    label="H1 заголовок на казахском"
                  />
                  <span class="text-description grey--text">
                    Если поле не заполнено, то подставляется название коллекции
                  </span>
                  <StoryTextField
                    v-model="collection.seoTitleKz"
                    class="mt-3"
                    label="SEO заголовок на казахском"
                  />
                  <StoryTextField
                    v-model="collection.seoTextKz"
                    label="SEO текст на казахском"
                  />
                  <StoryTextField
                    v-model="collection.seoDescriptionKz"
                    label="SEO description на казахском"
                  />
                  <div class="text-description grey--text">
                    Если данное поле не заполнено, по умолчанию поле будет заполнено
                    данными из: <br>
                    1. SEO текста (первые 500 символов) или; <br>
                    2. Названия kz и общего описания для всего сайта.
                  </div>
                </VCol>
              </VRow>
            </div>
          </div>
        </VCol>
        <VDivider vertical />
        <VCol class="pa-4" cols="3">
          <div>
            <div class="font-weight-bold mb-2">
              Статус
            </div>
            <State v-model="collection.state" />
          </div>
          <VDivider class="my-3" />
          <div>
            <div class="font-weight-bold mb-2">
              Позиция
            </div>
            <VTextField
              v-model.number="collection.position"
              v-mask="'#######'"
              background-color="#FAFAFB"
              class="position"
              dense
              flat
              hide-details
              placeholder="Укажите позицию"
              solo
            />
          </div>
          <VDivider class="mt-4 mb-3" />
          <div>
            <div id="date" class="font-weight-bold mb-2">
              Дата кампании
            </div>
            <DateTimePicker
              attach="#date"
              :date-end="collection.dateEnd"
              :date-start="collection.dateStart"
              :has-time-picker="false"
              @date-end="collection.dateEnd = $event"
              @date-start="collection.dateStart = $event"
            />
            <DurationPicker
              v-for="(scheduleTime, index) in collection.scheduleTimes"
              :key="index"
              :end-time="scheduleTime.endTime"
              :start-time="scheduleTime.startTime"
              :weekdays="scheduleTime.isodows"
              @delete="deleteScheduleTime(index)"
              @endTime="scheduleTime.endTime = $event"
              @startTime="scheduleTime.startTime = $event"
              @weekdays="scheduleTime.isodows = $event"
            />
            <VBtn
              class="mt-2 pl-1 pr-2"
              style="font-size: 38px"
              text
              @click="addScheduleTime"
            >
              <VIcon color="#A4A2B7" v-text="'mdi-plus'" />
              <span class="subtitle-1 font-weight-bold">Добавить время</span>
            </VBtn>
          </div>
          <VDivider class="my-3" />
          <ParamSelector
            v-model="collection.platform"
            attach="targets"
            item-key="code"
            :items="targets"
            title="Платформы"
          />
          <VDivider class="mt-5 mb-3" />
          <ParamSelector
            v-model="collection.zone.cities"
            attach="cities"
            height="277"
            :items="cities"
            title="Регионы"
          />
          <VDivider class="mt-4 mb-3" />
          <CollectionDesignPicker
            v-model="collection.design"
          />
          <VDivider class="my-3" />
          <CollectionConditionsPicker
            v-model="collection.condition"
          />
        </VCol>
      </VRow>
    </VForm>
    <div class="bottom-sticky grey lighten-5 z-1000">
      <VDivider />
      <div
        class="d-flex align-center px-4 py-3"
        :class="(id && !loading) ? 'justify-space-between' : 'justify-end'"
      >
        <span
          v-if="id && !loading"
          class="text--secondary"
        >
          {{
            collection.dateUpdated
              ? `Изменено: ${formatDate(collection.dateUpdated)}`
              : `Создано: ${formatDate(collection.dateCreated)}`
          }}
        </span>
        <div class="subtitle-2">
          <VBtn
            class="subtitle-2 font-weight-bold mr-2"
            color="#E43539"
            :disabled="loading"
            text
            @click="save()"
          >
            Сохранить
          </VBtn>
          <VBtn
            class="subtitle-2 br-8 white--text font-weight-bold blue-grey-disabled--text"
            color="#E43539"
            :disabled="loading"
            elevation="0"
            height="40"
            min-width="150"
            @click="save('active')"
          >
            Сохранить и опубликовать
          </VBtn>
        </div>
      </div>
    </div>
  </VCard>
</template>

<script>
import {
  fetchCollectionById,
  fetchCollectionPresets,
  createCollection,
  updateCollection,
} from '@/api/api';
import { formatDateWithTime } from '@/helpers/date';
import Breadcrumbs from '@/components/reusable/Breadcrumbs.vue';
import ImageUploadBox from '@/components/reusable/ImageUploadBox.vue';
import StoryTextField from '@/components/reusable/StoryTextField.vue';
import WideTextField from '@/components/reusable/WideTextField.vue';
import State from '@/components/reusable/State.vue';
import ChipGroup from '@/components/reusable/ChipGroup.vue';
import DateTimePicker from '@/components/reusable/DateTimePicker.vue';
import ParamSelector from '@/components/reusable/ParamSelector.vue';
import DurationPicker from '@/components/reusable/DurationPicker.vue';
import CollectionConditionsPicker from '@/components/collection/CollectionConditionsPicker.vue';
import CollectionDesignPicker from '@/components/collection/CollectionDesignPicker.vue';
import PresetRestaurantsPicker from '@/components/collection/PresetRestaurantsPicker.vue';
import PresetFoodsPicker from '@/components/collection/PresetFoodsPicker.vue';
import PresetCollectionsPicker from '@/components/collection/PresetCollectionsPicker.vue';
import PresetMaxDeliveryCost from '@/components/collection/PresetMaxDeliveryCost.vue';

export default {
  components: {
    PresetMaxDeliveryCost,
    PresetCollectionsPicker,
    PresetFoodsPicker,
    PresetRestaurantsPicker,
    CollectionDesignPicker,
    DurationPicker,
    CollectionConditionsPicker,
    ParamSelector,
    DateTimePicker,
    State,
    ChipGroup,
    WideTextField,
    Breadcrumbs,
    ImageUploadBox,
    StoryTextField,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    cities: {
      type: Array,
      default: () => [],
    },
    targets: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['close', 'update'],
  data() {
    return {
      collection: {
        typeOf: '',
        sort: '',
        titleRu: '',
        titleKz: '',
        state: 'disabled',
        design: null,
        zone: {
          cities: [],
        },
        condition: {
          key: '',
          value: {},
        },
        content: {
          presetId: null,
          value: {},
          valueWithTitles: [],
          int: null,
        },
        dateStart: '',
        dateEnd: '',
        scheduleTimes: [],
      },
      types: [
        { title: 'Рестораны', value: 'restaurant' },
        { title: 'Блюда', value: 'food' },
        { title: 'Мегаколлекция', value: 'collection' },
      ],
      sorts: [
        { title: 'По проценту вознаграждения', value: 'commission' },
        { title: 'По рейтингу', value: 'rating' },
        { title: 'По популярности', value: 'orders_count' },
        { title: 'Лучшее предложение', value: 'promotion' },
        { title: 'Цена за просмотр', value: 'view_price' },
      ],
      presets: [],
      showImage: false,
      loading: false,
      formatDate: formatDateWithTime,
      valid: true,
      rules: {
        required: (v) => !!v || 'Обязательное поле',
        notMoreThan28: (v) => (v && v.length < 29) || 'Название превышает 28 символов',
      },
    };
  },
  mounted() {
    if (this.id) {
      this.loading = true;
      this.collection.id = this.id;
      fetchCollectionById(this.id).then((response) => {
        this.collection = response;
        fetchCollectionPresets({ type_of: response.typeOf }).then((presets) => {
          this.presets = presets;
        });
      }).finally(() => {
        this.loading = false;
      });
    }
  },
  computed: {
    breadcrumbs() {
      return [{
        text: 'Коллекции',
        disabled: false,
        href: '/collections',
      },
      {
        text: this.id ? `Коллекция ID-${this.id}` : 'Создать новую коллекцию',
        disabled: true,
        href: '',
      }];
    },
  },
  methods: {
    resetCollectionContentValues() {
      this.collection.content.valueWithTitles = [];
      this.collection.content.int = null;
    },
    getCollectionPresets() {
      fetchCollectionPresets({ type_of: this.collection.typeOf }).then((response) => {
        this.presets = response;
        if (response.length > 0) {
          this.collection.content.presetId = response[0].id;
          this.resetCollectionContentValues();
        }
      });
      if (this.collection.sort !== '' && this.collection.typeOf !== 'restaurant') {
        this.collection.sort = '';
      }
    },
    addScheduleTime() {
      const scheduleTime = {
        startTime: '00:00',
        endTime: '23:59',
        isodows: [],
      };
      this.collection.scheduleTimes.push(scheduleTime);
    },
    deleteScheduleTime(index) {
      this.collection.scheduleTimes.splice(index, 1);
    },
    setCollectionContent() {
      const selectedParams = this.presets
        .find((preset) => preset.id === this.collection.content.presetId).params[0];
      this.collection.content.key = selectedParams.key;
      if (selectedParams.keyType === 'int') {
        this.collection.content.value[selectedParams.key] = this.collection.content.int || 0;
      } else {
        // eslint-disable-next-line max-len
        this.collection.content.value[selectedParams.key] = this.collection.content.valueWithTitles
          .map((value) => Number(value.id));
      }
    },
    save(state = this.collection.state) {
      if (this.$refs.form.validate()) {
        this.collection.state = state;
        this.setCollectionContent();
        if (this.id) {
          updateCollection(this.collection).then(() => this.$emit('update'));
        } else {
          createCollection(this.collection).then(() => this.$emit('update'));
        }
        this.$emit('close');
      }
    },
    deleteContentValue(index) {
      if (index >= 0) {
        this.collection.content.valueWithTitles.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

::v-deep {
  .v-select {
    .v-select__selection, .v-list-item__title {
      font-size: 16px;
    }
    input::placeholder {
      color: #4D4F67;
    }
    .mdi-chevron-down {
      font-size: 18px;
    }
    .v-ripple__container {
      display:none !important;
    }
  }

  .delivery-max-cost {
    max-width: 432px;
    .v-input__append-inner {
      margin-top: 10px;
    }
  }

  .position {
    input {
      font-size: 16px;
    }
  }

  .v-select .v-list {
    padding: 8px !important;
  }
  .v-list-item--link:hover {
    border-radius: 4px;
  }
  .v-list-item--link:hover, .v-list-item--link:before {
    background-color: #FFEEEE !important;
  }
  .v-list-item--active::before {
    opacity: 0 !important;
  }
}
</style>
