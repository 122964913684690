<template>
  <div>
    <div class="font-weight-bold mb-2">
      Местоположение коллекции
    </div>
    <VMenu
      v-model="menu"
      attach
      :close-on-content-click="false"
      content-class="br-8"
      min-width="240"
      offset-y
    >
      <template #activator="{ on, attrs }">
        <VBtn
          v-bind="attrs"
          class="subtitle-1 elevation-0 grey lighten-5"
          color="black"
          outlined
          :ripple="false"
          rounded
          style="border: 1px solid #E6E6E9 !important"
          v-on="on"
        >
          {{ conditionTitlesBySelectedPage[condition.selectedPage] || 'Выбрать местоположение' }}
          <VIcon
            class="ml-2"
            size="18"
            v-text="'mdi-chevron-down'"
          />
        </VBtn>
      </template>
      <VList class="pa-2" nav>
        <VListItem
          class="pink--hover my-0"
          dense
          :disabled="!!condition.value.ids"
          :ripple="false"
          @click="setValues('page', 'pages', [{id: 'main'}])"
        >
          <span :class="{ 'red--text': condition.selectedPage === 'main' }">
            Главный экран
          </span>
        </VListItem>
        <VListItem
          class="pink--hover my-0"
          dense
          :disabled="!!condition.value.ids"
          :ripple="false"
          @click="setValues('page', 'pages', [{id: 'order_page'}])"
        >
          <span :class="{ 'red--text': condition.selectedPage === 'order_page' }">
            Страница заказа
          </span>
        </VListItem>
        <VListItem
          class="pink--hover my-0"
          dense
          :disabled="!!condition.value.ids"
          :ripple="false"
          @click="setValues('page', 'pages', [{id: 'restaurant_page'}])"
        >
          <span :class="{ 'red--text': condition.selectedPage === 'restaurant_page' }">
            Страница ресторана (Все)
          </span>
        </VListItem>
        <DropdownList
          v-for="(conditionKey, index) in Object.keys(items)"
          :key="index"
          :current-page="filters[conditionKey].page"
          :disabled="disableCondition(conditionKey)"
          is-custom
          :items="items[conditionKey]"
          :loading="loading"
          nudge-left="300"
          :selected="condition.valueWithTitles"
          :total-page="filters[conditionKey].totalPages"
          @activated="fetchItems"
          @page="updateCurrentPage($event.page)"
          @search="updateSearch"
          @selected="setValues(conditionKey, 'ids', $event)"
        >
          <template #activator>
            <VListItem
              class="pink--hover"
              dense
              :disabled="disableCondition(conditionKey)"
              :ripple="false"
              @click="selectedCondition = conditionKey"
            >
              <span>{{ conditionTitlesBySelectedPage[conditionKey] }}</span>
              <VSpacer />
              <VIcon
                color="#4D4F67"
                :disabled="disableCondition(conditionKey)"
                v-text="'mdi-chevron-right'"
              />
            </VListItem>
          </template>
        </DropdownList>
      </VList>
    </VMenu>
    <ChipGroup
      v-if="condition.value.ids && condition.valueWithTitles"
      class="mt-4"
      :items="condition.valueWithTitles"
      :max="5"
      @removeById="deleteConditionValue"
    />
    <VBtn
      v-show="condition.valueWithTitles.length > 5"
      class="subtitle-2 red--text elevation-0 px-3"
      color="#FFEEEE"
      height="32"
      @click="resetConditionValues"
    >
      Удалить все
    </VBtn>
  </div>
</template>

<script>
import DropdownList from '@/components/reusable/DropdownList.vue';
import ChipGroup from '@/components/reusable/ChipGroup.vue';
import { fetchCollections, fetchHubRestaurants } from '@/api/api';

export default {
  components: { ChipGroup, DropdownList },
  props: {
    value: {
      type: Object,
      default: () => ({
        key: '',
        value: {},
      }),
    },
  },
  emits: ['input'],
  data() {
    return {
      menu: false,
      condition: {
        key: '',
        value: {},
        valueWithTitles: [],
      },
      selectedCondition: 'restaurant',
      conditionTitlesBySelectedPage: {
        main: 'Главный экран',
        order_page: 'Страница заказа',
        restaurant_page: 'Страница ресторана (Все)',
        restaurant: 'Страница ресторана',
        collection: 'Страница коллекции',
      },
      items: {
        restaurant: [],
        collection: [],
      },
      filters: {
        restaurant: {
          page: 1,
          search: '',
          totalPages: 1,
        },
        collection: {
          page: 1,
          search: '',
          totalPages: 1,
        },
      },
      loading: false,
    };
  },
  mounted() {
    if (this.value.key.length > 0) {
      this.condition = this.value;
    }
  },
  methods: {
    getRestaurants() {
      this.loading = true;
      const params = {
        page: this.filters.restaurant.page,
        title: this.filters.restaurant.search,
      };
      fetchHubRestaurants(params).then((response) => {
        this.items.restaurant = response.dataShort || [];
        this.filters.restaurant.totalPages = response.totalPages || 1;
      }).finally(() => {
        this.loading = false;
      });
    },
    getCollections() {
      this.loading = true;
      fetchCollections({
        page: this.filters.collection.page,
        search: this.filters.collection.search,
      }).then((response) => {
        this.items.collection = response.data.map((item) => ({ id: item.id, title: item.title }));
        this.filters.collection.totalPages = response.total_page || 1;
      }).finally(() => {
        this.loading = false;
      });
    },
    fetchItems() {
      const fetchByKey = {
        restaurant: () => this.getRestaurants(),
        collection: () => this.getCollections(),
      };
      fetchByKey[this.selectedCondition]();
    },
    updateSearch(search = '') {
      this.filters[this.selectedCondition].search = search;
      this.filters[this.selectedCondition].page = 1;
      this.fetchItems();
    },
    emitCondition() {
      this.$emit('input', this.condition);
    },
    resetConditionValues() {
      this.condition = {
        key: '',
        value: {},
        valueWithTitles: [],
      };
      this.emitCondition();
    },
    deleteConditionValue(id) {
      if (this.condition.valueWithTitles.length > 1) {
        this.condition.value.ids = this.condition.value.ids.filter((value) => value !== id);
        this.condition.valueWithTitles = this.condition.valueWithTitles
          .filter((value) => value.id !== id);
        this.emitCondition();
      } else {
        this.resetConditionValues();
      }
    },
    setValues(key, valueKey, values) {
      if (values.length > 0) {
        this.condition.key = key;
        this.condition.value = {};
        this.condition.value[valueKey] = values.map((value) => value.id);
        this.condition.valueWithTitles = key === 'page' ? [] : values.map((value) => ({
          id: value.id,
          title: value.title,
        }));
        this.condition.selectedPage = key === 'page' ? values[0].id : key;
        this.emitCondition();
      } else {
        this.resetConditionValues();
      }
    },
    disableCondition(key) {
      return this.condition.key !== key && !!this.condition.valueWithTitles.length;
    },
    updateCurrentPage(page) {
      this.filters[this.selectedCondition].page = page;
      this.fetchItems();
    },
  },
  watch: {
    value() {
      this.condition = this.value;
    },
    menu(val) {
      if (val) {
        document.querySelector('.collection-dialog').classList.add('overflow-y-hidden');
      } else {
        document.querySelector('.collection-dialog').classList.remove('overflow-y-hidden');
      }
    },
  },
};
</script>
