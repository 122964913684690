<template>
  <VCard>
    <div class="d-flex justify-space-between full-width pl-8">
      <VTextField
        v-model="search"
        background-color="#FAFAFB"
        class="mb-8 mt-6 br-8"
        color="#4D4F67"
        dense
        outlined
        placeholder="Поиск по названию, id"
        prepend-inner-icon="mdi-magnify"
        style="max-width: 40%"
      />
      <VBtn
        class="ma-3"
        icon
        @click="close"
      >
        <VIcon v-text="'mdi-close'" />
      </VBtn>
    </div>
    <VDataTable
      v-model="selectedCollections"
      class="mx-8"
      disable-pagination
      fixed-header
      :headers="headers"
      :height="tableHeight"
      hide-default-footer
      item-key="id"
      :items="collections"
      :items-per-page="itemsPerPage"
      :loading="loading"
      show-select
    >
      <template v-slot:item.title="{ item }">
        <span class="subtitle-1 font-weight-bold">{{ item.title }}</span>
      </template>
      <template v-slot:item.contents="{ item }">
        <ChipGroup
          v-if="item.contents"
          :items="item.contents"
          :max="1"
          outlined
        />
      </template>
      <template v-slot:item.cities="{ item }">
        <ChipGroup
          v-if="item.cities"
          :items="item.cities"
          :max="1"
          outlined
        />
      </template>
      <template v-slot:item.state="{ item }">
        <State
          disabled
          height="32"
          small
          :value="item.state"
        />
      </template>
    </VDataTable>
    <div class="footer d-flex align-center pr-6">
      <VSpacer /><VSpacer />
      <div v-if="!loading" class="d-flex align-center full-height py-3 mr-12">
        <span class="mr-1">{{ `${countStart}-${countEnd}` }}</span>
        <span class="text--secondary">{{ `из ${totalCount}` }}</span>
        <VDivider
          class="ml-4 mr-1"
          inset
          vertical
        />
        <VBtn
          :disabled="currentPage === 1"
          icon
          small
          @click="currentPage -= 1"
        >
          <VIcon size="20" v-text="'mdi-chevron-left'" />
        </VBtn>
        <VBtn
          :disabled="currentPage === totalPage"
          icon
          small
          @click="currentPage += 1"
        >
          <VIcon size="20" v-text="'mdi-chevron-right'" />
        </VBtn>
      </div>
      <VSpacer />
      <div>
        <VBtn
          class="subtitle-2 font-weight-bold br-8"
          text
          @click="close"
        >
          Отмена
        </VBtn>
        <VBtn
          class="subtitle-2 font-weight-bold elevation-0 white--text br-8 px-6 ml-2"
          color="#E43539"
          @click="save"
        >
          Сохранить
        </VBtn>
      </div>
    </div>
  </VCard>
</template>

<script>
import ChipGroup from '@/components/reusable/ChipGroup.vue';
import State from '@/components/reusable/State.vue';
import { fetchCollections } from '@/api/api';

export default {
  components: { ChipGroup, State },
  props: {
    value: {
      type: Array, // selected collections
      default: () => [],
    },
    isShown: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'input'],
  data() {
    return {
      selectedCollections: [],
      collections: [],
      currentPage: 0,
      totalPage: 0,
      totalCount: 0,
      itemsPerPage: 20,
      search: '',
      searchTimer: null,
      loading: false,
      tableHeight: window.innerHeight - 300,
    };
  },
  mounted() {
    this.getCollections();
    this.selectedCollections = JSON.parse(JSON.stringify(this.value));
    this.$nextTick().then(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  computed: {
    headers() {
      return [
        this.formHeader('id', 'id'),
        this.formHeader('Название', 'title', '18%', true),
        this.formHeader('Тип коллекции', 'typeOfTitle', '13%', true),
        this.formHeader('Контент', 'contents', '26%'),
        this.formHeader('Регионы', 'cities', '20%', true),
        this.formHeader('Статус', 'state', '10%', true),
      ];
    },
    countStart() {
      return (this.currentPage - 1) * this.itemsPerPage + 1;
    },
    countEnd() {
      return this.countStart + this.collections.length - 1;
    },
  },
  methods: {
    formHeader(text = '', value = '', width = '', sortable = false) {
      return {
        text,
        value,
        width,
        sortable,
      };
    },
    getCollections(page = 1) {
      this.loading = true;
      fetchCollections({
        page,
        search: this.search,
      }).then((response) => {
        this.collections = response.data;
        this.currentPage = response.current_page;
        this.totalPage = response.total_page;
        this.itemsPerPage = response.limit;
        this.totalCount = response.total_count;
      }).finally(() => {
        this.loading = false;
      });
    },
    close() {
      this.$emit('close');
    },
    save() {
      this.$emit('input', this.selectedCollections);
      this.close();
    },
    onResize() {
      this.tableHeight = window.innerHeight - 300;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  watch: {
    value(val) {
      this.selectedCollections = JSON.parse(JSON.stringify(val));
    },
    currentPage(page) {
      this.getCollections(page);
    },
    search() {
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => this.getCollections(), 500);
    },
    isShown(isShown) {
      if (isShown) {
        this.selectedCollections = this.value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-data-table {
    td, th {
      font-size: 14px !important;
      height: 64px;
    }
    th {
      font-weight: bold;
      letter-spacing: normal !important;
    }
    .v-icon {
      color: #E6E6E9;
    }
    .mdi-minus-box, .mdi-checkbox-marked {
      color: #E43539;
    }
    &-header__icon {
      opacity: 1;
    }
    .v-progress-linear__background, .v-progress-linear__indeterminate {
      background-color: #E43539 !important;
      border-color: #E43539 !important;
    }
  }
}

.footer {
  border-top: 0.5px solid #E6E6E9;
  background-color: #FAFAFB;
  height: 64px;
}
</style>
