<template>
  <VTextField
    v-mask="'#######'"
    class="delivery-max-cost mt-2"
    color="#4D4F67"
    hide-details
    label="Укажите максимальную стоимость доставки"
    :value="value"
    @input="$emit('input', Number($event))"
  >
    <template v-slot:append>
      <b>&#8376;</b>
    </template>
  </VTextField>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
  },
  emits: ['input'],
};
</script>
